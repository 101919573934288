
body {
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 450;
}

header.masthead {
    background-color: #ffffff;
    color: #000;
    //background-image: url(img/bg-map.png);
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 3rem;
    padding-bottom: 4rem;
}

header.masthead .overlay {
    background-color: rgb(255, 255, 255);
}

.text-underline-warning {
    background:linear-gradient(
        0deg, rgba(255,255,255,0) 0%,
        rgba(255,255,255,0) 15%,
        #ffd028 15%,
        #ffd028 30%,
        rgba(255,255,255,0) 30%,
        rgba(255,255,255,0) 100%
      )
}

footer {
    padding: 2.5rem 0;
    color: #727272;
    text-align: center;
    background-color: #f9f9f9;
    border-top: .05rem solid #e5e5e5;
}
